import fetchAPI from "../Fetch";
import GoogleRating from "../Misc/GoogleReviews";
import React, { useEffect, useState } from "react";

interface GoogleReviewResponse {
    user_ratings_total: number;
    rating: number;
}

function GoogleReviewsContainer() {
    const [data, setData] = useState<GoogleReviewResponse | null>(null);

    useEffect(() => {
        async function fetchData() {
            // Essayez d'obtenir les données du Local Storage
            const cachedData = localStorage.getItem("googleReviews");

            if (cachedData) {
                setData(JSON.parse(cachedData));
            } else {
                const result = await fetchAPI("review/reviewGoogleProduct", "GET", false, true);
                // Stockez les données dans le Local Storage pour une utilisation future
                localStorage.setItem("googleReviews", JSON.stringify(result.response.result));
                setData(result.response.result);
            }
        }

        fetchData();
    }, []);

    if (!data) return null;

    return (
        <div className="fixed bottom-0 left-0 mb-4 ml-4" style={{ zIndex: 9999 }}>
            <GoogleRating reviewsCount={data.user_ratings_total} starCount={parseInt(String(data.rating))} rating={data.rating} />
        </div>
    );
}//oskour
function GoogleReviewsContainerDur() {
    return (
        <div className="fixed bottom-0 left-0 mb-4 ml-4" style={{ zIndex: 9999 }}>
            <GoogleRating reviewsCount={205} starCount={parseInt(String(4.8))} rating={4.8} />
        </div>
    );
}
export { GoogleReviewsContainer, GoogleReviewsContainerDur }
